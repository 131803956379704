import { SysConfig } from 'resources/sysConfig';

export const eftenConfig: SysConfig = {
    languages: ['et', 'en', 'lt'],
    lng: 'et',
    fallbackLng: 'en',
    tenantRegistration: {
        terms: {
            urlEng: 'https://bidrento.com/{{lang}}/terms-and-conditions/',
            type: 'termsAndConditions',
        },
        extraTerms: {
            urlEng: 'https://eftenliving.ee/{{lang}}/privacy-policy/',
            type: 'privacyPolicy',
        },
    },
    dashboardComponents: {
        DashboardLogo: {
            id: 'DashboardLogo',
            position: 0,
            visible_for: '*',
            except_user_emails: [],
        },
        NotificationsBlock: {
            position: 0,
            visible_for: '*',
            except_user_emails: [],
        },
        UnpaidInvoicesNoticeBlock: {
            position: 0,
            visible_for: '*',
            except_user_emails: [],
        },
        TipsAndTricksPinBlock: {
            position: 0,
            visible_for: '*',
            except_user_emails: [],
        },
        CO2Badge: {
            position: 0,
            visible_for: '*',
            except_user_emails: [],
        },
        LarvaDevicesWidgetBlock: {
            position: 0,
            visible_for: '*',
            except_user_emails: [],
        },
        ExtraServicesButtonsBlock: {
            position: 0,
            visible_for: null,
            except_user_emails: [],
        },
        OrderExtraServiceAndMaintenanceButtons: {
            position: 0,
            visible_for: '*',
            except_user_emails: [],
        },
        OrderServiceButtonBlock: {
            position: 0,
            visible_for: null,
            except_user_emails: [],
        },
        CO2OverviewChartBlock: {
            position: 0,
            visible_for: '*',
            except_user_emails: [],
        },
        MeterReadingDashboardChartsBlock: {
            position: 0,
            visible_for: '*',
            except_user_emails: [],
        },
        TipsAndTricksListBlock: {
            position: 0,
            visible_for: '*',
            except_user_emails: [],
        },
    },
    larva: {
        showIfAvailable: true,
        alwaysAvailableForEmails: [],
    },
    meterReading: {
        delete: {
            allowedInHours: 72,
        },
    },
    appType: 'eften',
};

import { Resources } from 'resources/resources';
import LogoSmallSVG from './imgs/eftenliving-short-hor-pos-rgb.svg';
import LogoLoginPageSVG from './imgs/eftenliving-compact-hor-pos-rgb.svg';
import NoItemsSVG from './imgs/no-items.svg';
import NoItemsImageSVG from './imgs/no-items.svg';
import WriteSomethingSVG from './imgs/writeSomething.svg';
import WriteSomethingImageSVG from './imgs/writeSomething.svg';
import DrawSomethingSVG from './imgs/drawSomething.svg';
import DrawSomethingImageSVG from './imgs/drawSomething.svg';
import GreatSearchSVG from './imgs/great-search.svg';
import GreatSearchImageSVG from './imgs/great-search.svg';
import UserIconSVG from './icons/user.svg';
import CloseIconSVG from './icons/close.svg';
import UncheckedIconSVG from './icons/indeterminateCheckboxIcon.svg';
import CheckedIconSVG from './icons/checkedIcon.svg';
import WorldIconSVG from './icons/world.svg';
import BurgerIconSVG from './icons/menu.svg';
import HomeIconSVG from './icons/home.svg';
import HomeExtraServicesIconSVG from './icons/extraServices/home.svg';
import KebabIconSVG from './icons/kebab.svg';
import FilterIconSVG from './icons/filter.svg';
import BubbledBellSVG from './icons/bubbled-bell.svg';
import StorageExtraServiceIconSVG from './icons/extraServices/storage.svg';
import ParkingExtraServiceIconSVG from './icons/extraServices/parking.svg';
import BicycleExtraServiceIconSVG from './icons/extraServices/bicycle.svg';
import PencilIconSVG from './icons/pencil.svg';
import ConfirmCheckSVG from './icons/confirmCheck.svg';
import RightChevronIconSVG from './icons/chevron-right.svg';
import Pencil18IconSVG from './icons/pencil_18.svg';
import ConfirmCheckWhiteIconSVG from './icons/confirmCheckNeutral.svg';
import SmartHomeIconSVG from './icons/smart-house.svg';
import WarningBubbleIconSVG from './icons/warning-bubble.svg';
import ExtraServiceMenuIconSVG from './icons/extra-service.svg';
import ElectricityIconSVG from './icons/electricity.svg';
import ElectricityPlumbingIconSVG from './icons/plumbing.svg';
import ElectricityApplianceIconSVG from './icons/washing_machine.svg';
import ElectricityLocksmithIconSVG from './icons/key.svg';
import ElectricityHeatingIconSVG from './icons/radiator.svg';
import ElectricityAirConditioningIconSVG from './icons/air.svg';
import TipsAndTricksIconSVG from '../default/icons/tips-and-tricks.svg';
import TipsAndTricksPreviewPNG from '../default/imgs/tipsAndTricksPreview.png';

export const resources: Resources = {
    LogoBig: LogoLoginPageSVG,
    LogoSmall: LogoSmallSVG,
    LogoLoginPage: LogoLoginPageSVG,
    NoItemsIcon: NoItemsSVG,
    WriteSomethingIcon: WriteSomethingSVG,
    DrawSomethingIcon: DrawSomethingSVG,
    GreatSearchIcon: GreatSearchSVG,
    UserIcon: UserIconSVG,
    CloseIcon: CloseIconSVG,
    UncheckedIcon: UncheckedIconSVG,
    CheckedIcon: CheckedIconSVG,
    WorldIcon: WorldIconSVG,
    BurgerIcon: BurgerIconSVG,
    HomeIcon: HomeIconSVG,
    HomeExtraServicesIcon: HomeExtraServicesIconSVG,
    KebabIcon: KebabIconSVG,
    FilterIcon: FilterIconSVG,
    NoItemsImage: NoItemsImageSVG,
    WriteSomethingImage: WriteSomethingImageSVG,
    DrawSomethingImage: DrawSomethingImageSVG,
    GreatSearchImage: GreatSearchImageSVG,
    BubbledBell: BubbledBellSVG,
    StorageExtraServiceIcon: StorageExtraServiceIconSVG,
    ParkingExtraServiceIcon: ParkingExtraServiceIconSVG,
    BicycleExtraServiceIcon: BicycleExtraServiceIconSVG,
    PencilIcon: PencilIconSVG,
    ConfirmCheck: ConfirmCheckSVG,
    RightChevronIcon: RightChevronIconSVG,
    Pencil18Icon: Pencil18IconSVG,
    ConfirmCheckWhiteIcon: ConfirmCheckWhiteIconSVG,
    SmartHomeIcon: SmartHomeIconSVG,
    WarningBubbleIcon: WarningBubbleIconSVG,
    ExtraServiceMenuIcon: ExtraServiceMenuIconSVG,
    ElectricityIcon: ElectricityIconSVG,
    ElectricityPlumbing: ElectricityPlumbingIconSVG,
    ElectricityAppliance: ElectricityApplianceIconSVG,
    ElectricityLocksmith: ElectricityLocksmithIconSVG,
    ElectricityHeating: ElectricityHeatingIconSVG,
    ElectricityAirConditioning: ElectricityAirConditioningIconSVG,
    TipsAndTricks: TipsAndTricksIconSVG,
    TipsAndTricksDefaultPreviewImage: TipsAndTricksPreviewPNG,
};

import { ThemeProvider } from '@material-ui/core';
import { AppRoot } from 'appRoot';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ResourceContext } from 'resources/context';
import { resources } from 'resources/eften';
import theme from 'resources/eften/theme';
import { ConfigContext } from 'resources/config';
import { eftenConfig } from 'resources/eften/eftenConfig';

import './resources/eften/theme.css';
import './resources/eften/styles.css';
import './resources/eften/addition.css';

window.addEventListener('load', () => {
    ReactDOM.render(
        <ResourceContext.Provider value={resources}>
            <ConfigContext.Provider value={eftenConfig}>
                <ThemeProvider theme={theme}>
                    <BrowserRouter>
                        <AppRoot />
                    </BrowserRouter>
                </ThemeProvider>
            </ConfigContext.Provider>
        </ResourceContext.Provider>,
        document.getElementById('root'),
    );
});

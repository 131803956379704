// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("resources/eften/imgs/quadrangle.svg?inline", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("resources/eften/imgs/quadrangle-yellow.svg?inline", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("resources/eften/imgs/quadrangle-red.svg?inline", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".public-page-container {\n    background-image: linear-gradient(var(--app-fill-login-color-bottom), var(--app-fill-login-color-top));\n}\n\n.company-intro__container--desktop {\n    padding-top: 0;\n    position: static;\n    align-items: center;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "),\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + "),\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n    background-position: -85px calc(100vh - 274px), calc(100% + 100px) -57px, right bottom;\n    background-repeat: no-repeat, no-repeat, no-repeat;\n    overflow: hidden;\n}\n\n.company-intro__container--mobile {\n    width: 50%;\n}\n\n.login-page__intro .company-intro__wrapper--mobile{\n    justify-content: center!important;\n}\n", "",{"version":3,"sources":["webpack://./src/resources/eften/addition.css"],"names":[],"mappings":"AAAA;IACI,sGAAsG;AAC1G;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,mBAAmB;IACnB;;+CAEyD;IACzD,sFAAsF;IACtF,kDAAkD;IAClD,gBAAgB;AACpB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,iCAAiC;AACrC","sourcesContent":[".public-page-container {\n    background-image: linear-gradient(var(--app-fill-login-color-bottom), var(--app-fill-login-color-top));\n}\n\n.company-intro__container--desktop {\n    padding-top: 0;\n    position: static;\n    align-items: center;\n    background-image: url('resources/eften/imgs/quadrangle.svg?inline'),\n        url('resources/eften/imgs/quadrangle-yellow.svg?inline'),\n        url('resources/eften/imgs/quadrangle-red.svg?inline');\n    background-position: -85px calc(100vh - 274px), calc(100% + 100px) -57px, right bottom;\n    background-repeat: no-repeat, no-repeat, no-repeat;\n    overflow: hidden;\n}\n\n.company-intro__container--mobile {\n    width: 50%;\n}\n\n.login-page__intro .company-intro__wrapper--mobile{\n    justify-content: center!important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import theme from '../default/theme';

const eftenTheme = { ...theme };

eftenTheme.palette = {
    ...theme.palette,
    background: {
        default: '#eee',
        paper: '#fff',
    },
    secondary: {
        ...theme.palette.secondary,
        main: '#F69278',
    },
};

export default eftenTheme;

import { useMemo } from 'react';
import useWindowSize, { VIEWPORT_BREAKPOINTS } from '../../hooks/useWindowSize';

import { LinearProgress } from '@material-ui/core';
import { LogoBig } from '../Logo';

import './styles/Intro.css';
import { useSelector } from 'react-redux';
import { AppState } from 'core/redux/rootReducer';

export const Intro: IntroType = ({ isLoading = false, classes = '' }: IntroProps) => {
    const { getScreenWidthClassName } = useWindowSize();
    const SCREEN_CLASSNAME = useMemo(
        () => getScreenWidthClassName(VIEWPORT_BREAKPOINTS.w680),
        [getScreenWidthClassName],
    );
    const isMobileWidth = useSelector(({ core }: AppState) => core.isMobileWidth);

    return useMemo(() => {
        return isMobileWidth ? (
            <div />
        ) : (
            <div
                className={
                    `company-intro company-intro__container company-intro__container--${SCREEN_CLASSNAME} ` + classes
                }
            >
                <div className={`company-intro__wrapper company-intro__wrapper--${SCREEN_CLASSNAME} `}>
                    {!isLoading && (
                        <LogoBig classes={`company-intro__logo company-intro__logo--desktop`} width={'165px'} />
                    )}
                    {isLoading && (
                        <>
                            <LogoBig classes="company-intro__logo" />
                            <LinearProgress className="company-intro__loading" />
                        </>
                    )}
                </div>
            </div>
        );
    }, [isMobileWidth, SCREEN_CLASSNAME, isLoading, classes]);
};
export default Intro;

type IntroType = (props: IntroProps) => JSX.Element;
export type IntroProps = {
    isLoading?: boolean;
    classes?: string;
};
